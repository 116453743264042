import styled from '@emotion/styled'
import { Input as BaseInput } from '@mui/base/Input'
import {
  Alert,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  StepLabel,
  Switch,
} from '@mui/material'
import MUIButton from '@mui/material/Button'
import AddButton from 'components/AddButton/AddButton'
import Button from 'components/Button'
import Calendar from 'components/Calendar'
import Info from 'components/Info'
import Input from 'components/Input'
import Label from 'components/Label'
import SimpleLoading from 'components/Loading'
import ModalComponent from 'components/Modal'
import BasicSelect from 'components/Select'
import MuiSwitch from 'components/Switch'
import TimePicker from 'components/TimePicker'
import convertTimeTypesToSwedishfrom from 'functions/convertTimeTypesToSwedish'
import getColorsByType from 'functions/getColorsByType'
import {
  Project,
  SubTask,
  useAddSubTaskMutation,
  useGetProjectsByCompanyLazyQuery,
  useGetTasksQuery,
  useSetDailyTimeAndLeaveMutation,
} from 'generated/graphql'
import withMuiTheme from 'hoc/withMuiTheme'
import useAuth from 'hooks/Auth.hook'
import { useTheme } from 'hooks/theme.hook'
import useTime from 'hooks/Time.hook'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { ButtonGroup } from 'react-bootstrap'
import { BsCalendar2RangeFill } from 'react-icons/bs'
import { FaProjectDiagram, FaRegCalendarCheck } from 'react-icons/fa'
import { GoProject } from 'react-icons/go'
import { IoCloseCircle } from 'react-icons/io5'
import { MdDateRange, MdFilterAltOff, MdMoreTime } from 'react-icons/md'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LeavesVariationsEnum } from 'types/sharedTypes'
import routes from 'utils/routes.json'
import { themeColor } from 'utils/theme'
import './TimeRapport.scss'

interface InputData {
  startDate: Date
  endDate: Date
  key: string
  type: string
  color: string
  cellTextColor?: string
}
// Utility function to add hours to a Date object
const addHours = (date: Date, hours: number) => {
  const result = new Date(date)
  result.setHours(result.getHours() + hours)
  return result
}
const inputData: InputData[] = []
interface GroupedSubTasks {
  [subProjectName: string]: SubTask[] // Or use [subProjectId: string] if grouping by ID
}
function TimeReport() {
  const user = useAuth()
  const [beginDate, setBeginDate] = useState(new Date())
  const [finishDate, setFinishDate] = useState(new Date())

  const [calendarInfo, setCalendarInfo] = useState({
    isMinimized: false,
    selectedDate: new Date(),
  })
  const [success, setSuccess] = useState(false)
  const [isHome, setIsHome] = useState(false)
  const [buttonValue, setButtonValue] = useState('Låter bra!')
  const [projectSelectValue, setProjectValue] = useState('')
  const [taskSelectValue, setTaskValue] = useState('')
  const [formErrors, setFormErrors] = useState<any>({})
  const [showMultipleRegistration, setShowMultipleRegistration] = useState(true)
  const [filteredSubTasks, setFilteredSubTasks] = useState<SubTask[]>()
  const [groupedSubTasks, setGroupedSubTasks] = useState<GroupedSubTasks>({})
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: tasksData } = useGetTasksQuery({ fetchPolicy: 'network-only' })
  const [isOpenComparedToBudgetModal, setIsOpenComparedToBudgetModal] =
    useState(false)
  const [workFormData, setWorkFormData] = useState<WorkEntry[]>()
  const [leaveFormData, setLeaveFormData] = useState<LeaveEntry[]>()
  const [activeLink, setActiveLink] = useState('Arbete')

  const [getProjects, { data: projectsData }] =
    useGetProjectsByCompanyLazyQuery({ fetchPolicy: 'network-only' })
  const [
    setDailyTimeAndLeave,
    {
      data: setDailyTimeAndLeaveData,
      loading: setDailyTimeAndLeaveLoading,
      error: setDailyTimeAndLeaveError,
    },
  ] = useSetDailyTimeAndLeaveMutation({ fetchPolicy: 'network-only' })
  const theme = useTheme()
  const navigate = useNavigate()

  const {
    addTime,
    addTimeData: { data, loading, error },
    compareTimeToMaxBudget: [compareTimeToBudgetFn],
  } = useTime()
  async function _fetchProjects(companyId: string) {
    await getProjects({
      variables: { id: companyId },
      onCompleted: (projectsData) => {
        // Group subTasks by project and subProject
        const groupedSubTasks = projectsData?.getProjectsByCompany?.reduce(
          (acc: any, project) => {
            const projectName = project?.name
            if (!projectName) return acc

            // Initialize the project group if it doesn't exist
            if (!acc[projectName]) {
              acc[projectName] = {}
            }

            project?.subProjects?.forEach((subProject) => {
              if (subProject?.tasks) {
                subProject.tasks.forEach((task) => {
                  if (task?.subTasks) {
                    // Initialize the subProject group if it doesn't exist within the project
                    if (!acc[projectName][subProject.name as string]) {
                      acc[projectName][subProject.name as string] = []
                    }
                    // Add subTasks to the corresponding project and subProject group
                    acc[projectName][subProject.name as string].push(
                      ...task.subTasks
                    )
                  }
                })
              }
            })

            return acc
          },
          {}
        )
        setGroupedSubTasks(groupedSubTasks)
      },
    })
  }
  function refetchProjects() {
    if (user.user?.currentUser?.company?._id) {
      _fetchProjects(user.user?.currentUser?.company?._id)
    }
  }
  useEffect(() => {
    if (user.user?.currentUser?.company?._id) {
      _fetchProjects(user.user?.currentUser?.company?._id)
    }
  }, [user.user?.currentUser])

  const validateForm = () => {
    let errors: any = {}
    if (!projectSelectValue) errors.project = 'Project is required'
    if (!taskSelectValue) errors.task = 'Task is required'
    if (!beginDate) errors.beginDate = 'Start time is required'
    if (!finishDate) errors.finishDate = 'Finish time is required'
    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }
  function removeLocalStorage() {
    if (
      localStorage.getItem('selectedDate') ||
      localStorage.getItem('isMinimizedCalendar')
    ) {
      localStorage.removeItem('selectedDate')

      localStorage.removeItem('isMinimizedCalendar')
    } else {
      window.removeEventListener('beforeunload', () => null)
    }
  }
  useEffect(() => {
    removeLocalStorage()
  }, [])

  const onSubmit = async () => {
    if (!validateForm()) {
      return // Stop the submission if validation fails
    }

    const spendedHour = moment(finishDate).diff(moment(beginDate), 'hours')

    compareTimeToBudgetFn({
      variables: {
        spendedHour: spendedHour.toString(),
        projectName: projectSelectValue,
      },
      onCompleted: (res) => {
        if (res.compareTimeToMaxBudget === false) {
          setIsOpenComparedToBudgetModal(true)
        } else {
          addTimeEntry()
        }
      },
    })
  }

  const addTimeEntry = () => {
    addTime({
      variables: {
        beginDate,
        finishDate,
        userId: user.user?.currentUser?.id!,
        fromHome: isHome,
        project: projectSelectValue,
        task: taskSelectValue,
      },
      onCompleted: (r) => {
        setSuccess(true)
        setIsOpenComparedToBudgetModal(false)
        setTimeout(() => {
          navigate(
            `${routes.report_month.path}?project=${projectSelectValue}&task=${taskSelectValue}&activeButton=userTimes`
          )
        }, 1500)
      },
      onError: (e) => {
        setSuccess(false)
      },
    })
  }

  const handleChangeTask = (e: SelectChangeEvent) => {
    setTaskValue(e.target.value)
    setFormErrors({})
  }

  const handleChangeProject = (e: SelectChangeEvent) => {
    setProjectValue(e.target.value)
    setFormErrors({})

    // Find the selected project by name
    const selectedProject = projectsData?.getProjectsByCompany?.find(
      (project) => project?.name === e.target.value
    )

    const groupedSubTasks = projectsData?.getProjectsByCompany
      ?.filter((project) => project?.name === e.target.value)
      ?.reduce((acc: any, project) => {
        const projectName = project?.name
        if (!projectName) return acc

        // Initialize the project group if it doesn't exist
        if (!acc[projectName]) {
          acc[projectName] = {}
        }

        project?.subProjects?.forEach((subProject) => {
          if (subProject?.tasks) {
            subProject.tasks.forEach((task) => {
              if (task?.subTasks) {
                // Initialize the subProject group if it doesn't exist within the project
                if (!acc[projectName][subProject.name as string]) {
                  acc[projectName][subProject.name as string] = []
                }
                // Add subTasks to the corresponding project and subProject group
                acc[projectName][subProject.name as string].push(
                  ...task.subTasks
                )
              }
            })
          }
        })

        return acc
      }, {})

    setGroupedSubTasks(groupedSubTasks)
  }

  function handleFormClick() {
    if (workFormData?.length === 0 && leaveFormData?.length == 0) {
      return
    }
    // Initialize the start time at 8:00 AM
    const initialHour = 8
    const initialMinute = 0

    // Function to set hours and minutes while preserving the date
    const setTime = (date: Date, hours: number, minutes: number): Date => {
      const updatedDate = new Date(date)
      updatedDate.setHours(hours, minutes, 0, 0) // Set hours, minutes, seconds, milliseconds
      return updatedDate
    }

    // Start with the initial time for the first entry
    let currentBeginTime: Date | null = null

    const fixedWorkEntries = workFormData?.map((entry, index) => {
      // For the first entry, set the beginTime to 8:00 AM of the entry's date
      if (index === 0) {
        currentBeginTime = setTime(entry.beginTime, initialHour, initialMinute)
      } else if (currentBeginTime) {
        // For subsequent entries, set the beginTime to the previous entry's endTime
        currentBeginTime = new Date(currentBeginTime)
      }

      // Calculate the endTime by adding spendedTime hours to the beginTime
      const currentEndTime = new Date(currentBeginTime as Date)
      currentEndTime.setHours(currentEndTime.getHours() + entry.spendedTime)

      // Update the entry with the new times
      const updatedEntry: WorkEntry = {
        ...entry,
        beginTime: currentBeginTime as Date,
        endTime: currentEndTime,
      }

      // Update currentBeginTime for the next iteration
      currentBeginTime = currentEndTime

      return updatedEntry
    })

    setDailyTimeAndLeave({
      variables: {
        works: fixedWorkEntries?.map((r, i) => ({
          ...r,
          beginTime: new Date(new Date(r.beginTime)),
          endTime: new Date(
            new Date(r.endTime).setHours(
              new Date(r.beginTime).getHours() + r.spendedTime
            )
          ),
        })),
        leaves: leaveFormData,
      },
      onCompleted: (data) => {
        if (data.setDailyTimeAndLeave) {
          setSuccess(true)

          setTimeout(() => {
            navigate(`${routes.report_month.path}/?activeButton=userTimes`)
          }, 1500)
        }
      },
      onError: (error) => {
        console.log(error)
      },
    })
  }
  useEffect(() => {
    const showMultipleRegistration = searchParams.get(
      'showMultipleRegistration'
    )

    if (activeLink === 'Frånvaro') {
      navigate('/report/leave')
    }
    if (showMultipleRegistration === 'false') {
      setShowMultipleRegistration(false)
    }
  }, [activeLink, searchParams])

  const totalWorkAndLeaveSpent = (
    workEntries: WorkEntry[],
    leaveEntries: LeaveEntry[]
  ) => {
    if (workEntries && leaveEntries) {
      const worksSum = workEntries.reduce((total: any, entry: any) => {
        const hours = entry.spendedTime
        return total + hours
      }, 0)
      const leavesSum = leaveEntries.reduce((total: any, entry: any) => {
        const hours = entry.spendedTime
        return total + hours
      }, 0)
      return worksSum + leavesSum
    } else return 0
  }

  return (
    <div className="time-rapport-wrapper" style={{ position: 'relative' }}>
      <div>
        <Label label={routes.report_time.component} />
      </div>
      {calendarInfo.isMinimized ? (
        <div className="time-registering-form-parent">
          <div
            style={{
              margin: 'auto',
              marginBottom: '4%',
              display: 'grid',
              gridTemplateColumns: '9fr 1fr',
              gridTemplateRows: 'auto',
            }}
          >
            <BasicSelect
              value={projectSelectValue}
              label="Projekt Namn"
              defaultInput="Filtrera på projekt"
              values={
                projectsData?.getProjectsByCompany
                  ? (projectsData?.getProjectsByCompany as any[])?.length
                    ? (projectsData?.getProjectsByCompany as any[]).map(
                        (r) => r
                      )
                    : []
                  : []
              }
              handleChange={handleChangeProject}
            />
            <div className="place-middle" style={{ height: '100%' }}>
              <MdFilterAltOff
                size={28}
                className="icon"
                onClick={() => {
                  user.user?.currentUser?.company?._id &&
                    _fetchProjects(user.user?.currentUser?.company?._id)
                }}
              />
            </div>
          </div>
          <div className="time-registering-form__label">
            <label style={{ fontWeight: 'bold', color: '#fff' }}>
              Valt datum:
            </label>
            <span style={{ color: themeColor, fontWeight: 'bold' }}>
              {calendarInfo.selectedDate
                ? moment(calendarInfo.selectedDate).format('dddd YYYY-MM-DD')
                : 'Välja ett datum'}
            </span>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {' '}
              <FaRegCalendarCheck
                className="icon"
                color={themeColor}
                size={33}
                onClick={() => {
                  setCalendarInfo({ ...calendarInfo, isMinimized: false })
                  localStorage.setItem('isMinimizedCalendar', 'false')
                }}
              />{' '}
              <span style={{ fontSize: '12px', marginTop: '4px' }}>
                Kalender
              </span>
            </div>
          </div>
          <h3>Arbetstider:</h3>
          <TimeRegisteringForm
            onGetData={(d) => setWorkFormData(d)}
            tasks={groupedSubTasks as GroupedSubTasks}
            currentDate={calendarInfo.selectedDate.toString()}
            type="work"
            projects={projectsData?.getProjectsByCompany as Project[]}
            refetchProjects={refetchProjects}
          />
          <h3>Frånvaro:</h3>
          <LeaveRegisteringForm
            onGetData={(d) => setLeaveFormData(d)}
            tasks={filteredSubTasks as SubTask[]}
            currentDate={calendarInfo.selectedDate.toString()}
            type="leave"
          />
          <div className="total-times-wrapper">
            <span>Total Registerade tider: </span>
            <span>{`${totalWorkAndLeaveSpent(
              workFormData as WorkEntry[],
              leaveFormData as LeaveEntry[]
            )} Timmar`}</span>
          </div>
          {success && setDailyTimeAndLeaveData?.setDailyTimeAndLeave && (
            <Info type="success">Tider skapades</Info>
          )}
          <div className="times-submit-button">
            {setDailyTimeAndLeaveLoading ? (
              <SimpleLoading />
            ) : (
              <Button
                label={'Låter bra'}
                onClick={handleFormClick}
                width="98%"
                loading={
                  setDailyTimeAndLeaveData?.setDailyTimeAndLeave == true &&
                  success &&
                  !setDailyTimeAndLeaveError
                }
              />
            )}
          </div>

          <ModalComponent
            bgColor="transparent"
            isModalOpen={isOpenComparedToBudgetModal}
            setIsModalOpen={setIsOpenComparedToBudgetModal}
          >
            <Info type="warning">Over budget</Info>
            <Button
              label={'Vill du fortsätta?'}
              onClick={() => {
                addTimeEntry()
              }}
              width="50%"
            />
          </ModalComponent>
        </div>
      ) : null}
      {showMultipleRegistration ? (
        <div className="calendars-container">
          <Calendar
            loading={false}
            containerStyle={{
              border: '1px solid rgba(211, 211, 211, 0.294)',
              padding: '0.5rem',
            }}
            backgroundColor="transparent"
            getCalendarInfo={(calendarInfo: any) =>
              setCalendarInfo(calendarInfo)
            }
            activeDate={moment().toDate()}
            textColor="#fff"
            borderColor="transparent"
            guidComponentStyle={{
              backgroundColor: '#383838',
              padding: '0.4rem',
              margin: '1rem',
            }}
            handleRangeClick={(range: any) =>
              console.log('Range clicked:', range)
            }
            onShownDateChange={(date: any) =>
              console.log('Date changed:', date)
            }
            handleDayClick={(date: any) => {
              removeLocalStorage()
            }}
            data={inputData}
            guidHeaderColor="red"
            beginDayOfWeek={'monday'} //'sunday' or 'monday'
            GuildComponent={
              <Alert
                className="guid-component-parent"
                onClick={() => {
                  setShowMultipleRegistration(false)
                  setSearchParams({ showMultipleRegistration: 'false' })
                }}
                icon={<MdDateRange fontSize="inherit" />}
                sx={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                severity="success"
                action={
                  <div className="place-middle">
                    <MdMoreTime size={17} color={themeColor} />
                    <MUIButton color="primary" size="small">
                      Registera en i taget?
                    </MUIButton>
                  </div>
                }
              >
                Börja att välja en dag
              </Alert>
            }
          />
        </div>
      ) : (
        <div
          style={{ position: 'relative', maxWidth: '1200px', margin: 'auto' }}
          className={`fileds-wrapper ${
            theme?.theme === 'dark' ? 'white-icons' : 'dark-icons'
          }`}
        >
          <div
            style={{
              position: 'relative',
              right: 0,
              top: 0,
              marginBottom: '10px',
              display: 'flex',
              width: 'fit-content',
              marginLeft: 'auto',
            }}
            onClick={() => {
              setSearchParams({ showMultipleRegistration: 'true' })
              setShowMultipleRegistration(true)
            }}
          >
            <BsCalendar2RangeFill
              color={themeColor}
              style={{ marginRight: '10px', fontSize: '18px' }}
              className="icon"
            />
            <ButtonGroup className="navigation-buttons"></ButtonGroup>
            <span>Registera fler</span>
          </div>
          <div style={{ margin: 'auto', width: 'fit-content' }}>
            {' '}
            {[
              { id: 1, label: 'Frånvaro' },
              { id: 2, label: 'Arbete' },
            ].map((item) => (
              <MUIButton
                key={item.id}
                style={
                  activeLink === item.label
                    ? { color: themeColor, textDecoration: 'underline' }
                    : {}
                }
                onClick={() => {
                  setActiveLink(item.label as string)
                }}
              >
                {item.label}
              </MUIButton>
            ))}
          </div>

          <h2>När jobbade du?</h2>
          <div className="select-wrapper">
            <div style={{ margin: 'auto' }}>
              <div style={{ margin: 'auto', marginBottom: '4%' }}>
                <BasicSelect
                  value={projectSelectValue}
                  label="Projekt Namn"
                  defaultInput="Projekt Namn"
                  values={
                    projectsData?.getProjectsByCompany?.flatMap(
                      (r) => r?.subProjects
                    ) as any[]
                  }
                  handleChange={handleChangeProject}
                />
              </div>

              {formErrors.project && (
                <div className="place-middle">
                  <Info type="error">{formErrors.project}</Info>
                </div>
              )}
            </div>
            <div>
              <div style={{ margin: 'auto', marginBottom: '4%' }}>
                <BasicSelect
                  handleChange={handleChangeTask}
                  value={taskSelectValue}
                  label="Task"
                  defaultInput="Task"
                  values={
                    tasksData?.getTasks?.flatMap((r) => r?.subTasks) as [
                      { name: string; _id: number | string }
                    ]
                  }
                />
              </div>
              {formErrors.task && (
                <div className="place-middle">
                  <Info type="error">{formErrors.task}</Info>
                </div>
              )}
            </div>
          </div>
          <div className="switch-wrapper">
            <label>Hemifrån</label>
            <MuiSwitch
              handleChange={() => setIsHome(!isHome)}
              checked={isHome}
            />
          </div>
          <div className="times_parent">
            <TimePicker
              label="Starttid"
              value={beginDate}
              defaultValue={beginDate}
              handleChange={(val: Date) => setBeginDate(val)}
            />

            <TimePicker
              label="Sluttid"
              value={finishDate}
              defaultValue={finishDate}
              handleChange={(val: Date) => setFinishDate(val)}
            />
          </div>

          {loading ? <SimpleLoading /> : null}
          {error && !loading ? (
            <Info type="error">Unexpected Error</Info>
          ) : null}

          <ModalComponent
            bgColor="transparent"
            isModalOpen={isOpenComparedToBudgetModal}
            setIsModalOpen={setIsOpenComparedToBudgetModal}
          >
            <Info type="warning">Over budget</Info>
            <Button
              label={'Vill du fortsätta?'}
              onClick={() => {
                addTimeEntry()
              }}
              width="50%"
            />
          </ModalComponent>
          {success && data && <Info type="success">Tiden skapades</Info>}
          <div className="times-submit-button">
            <Button label={buttonValue} onClick={onSubmit} width="98%" />
          </div>
          {success &&
            data?.addTime?.subTimes &&
            data?.addTime?.subTimes?.length > 0 && (
              <div className="project-hour">
                <div>Idags timmarna:</div>
                <span>
                  <span>
                    <span>
                      {
                        data.addTime.subTimes[
                          data.addTime.subTimes.length - 1
                        ]?.timeSpend?.split(':')[0]
                      }
                    </span>
                    <span> Timmar</span>
                    <span> och </span>
                    <span>
                      {
                        data.addTime.subTimes[
                          data.addTime.subTimes.length - 1
                        ]?.timeSpend?.split(':')[1]
                      }
                    </span>
                    <span> Minuter totalt</span>
                  </span>
                </span>
              </div>
            )}
        </div>
      )}

      {setDailyTimeAndLeaveError && (
        <Info type="error">Unexpected error happend</Info>
      )}
    </div>
  )
}

export default withMuiTheme(TimeReport)
interface WorkEntry {
  id: string
  beginTime: Date
  endTime: Date
  spendedTime: number
  fromHome: boolean
}
const totalTimeSpent = (workEntries: any) =>
  workEntries.reduce((total: any, entry: any) => {
    const hours = entry.spendedTime
    return total + hours
  }, 0)
const TimeRegisteringForm = ({
  tasks,
  currentDate,
  type,
  onGetData,
  projects,
  refetchProjects,
}: {
  tasks: GroupedSubTasks
  currentDate: string
  type: 'work' // Clearly define the type
  onGetData: (data: WorkEntry[]) => void
  projects: Project[]
  refetchProjects?: () => void
}) => {
  const [workEntries, setWorkEntries] = useState<WorkEntry[]>([])
  const [showAddTaskModal, setShowAddTaskModal] = useState(false)
  const [selectedTask, setSelectedTask] = useState('')
  const [selectedSubProject, setSelectedSubProject] = useState('')
  const [totalWorkHours, setTotalWorkHours] = useState<number>(0)
  const [addedSubTask, setAddedSubTask] = useState('')
  const { data: tasksData } = useGetTasksQuery({ fetchPolicy: 'network-only' })
  const [
    addSubTask,
    {
      loading: addSubTaskLoading,
      data: addSubTaskData,
      error: addSubTaskError,
    },
  ] = useAddSubTaskMutation({ fetchPolicy: 'network-only' })

  // Send workEntries to the parent
  useEffect(() => {
    onGetData(workEntries)
    setTotalWorkHours(totalTimeSpent(workEntries))
  }, [workEntries, onGetData])

  // Utility function to add hours to a Date object
  const addHours = (date: Date, hours: number) => {
    const result = new Date(date)
    result.setHours(result.getHours() + hours)
    return result
  }

  // Handle input change for work entries
  const handleInputChange = (subTaskId: string, value: number) => {
    setWorkEntries((prevEntries) => {
      const lastEntry = prevEntries[prevEntries.length - 1]

      // Set beginTime to the endTime of the last entry, or 8:00 AM if no previous entry exists
      const currentTime = lastEntry
        ? lastEntry.endTime
        : moment(currentDate).set('hour', 8).minutes(0).toDate()

      // Calculate the new endTime based on the new beginTime and spendedTime (value)
      const newWorkEntry: WorkEntry = {
        id: subTaskId,
        beginTime: currentTime, // Begin time follows the endTime of the previous entry
        endTime: addHours(currentTime, value), // End time is based on spendedTime (value)
        spendedTime: value,
        fromHome: false,
      }

      // Check if there's an existing entry with the same subTaskId
      const existingEntryIndex = prevEntries.findIndex(
        (entry) => entry.id === subTaskId
      )

      if (existingEntryIndex !== -1) {
        // Update existing work entry (preserving time logic)
        const updatedEntries = [...prevEntries]
        updatedEntries[existingEntryIndex] = {
          ...newWorkEntry,
          beginTime: lastEntry ? lastEntry.endTime : currentTime,
          endTime: addHours(lastEntry ? lastEntry.endTime : currentTime, value),
        }
        return updatedEntries
      } else {
        // Add new work entry
        return [...prevEntries, newWorkEntry]
      }
    })
  }

  return (
    <div
      className="time-registering-form"
      style={{ width: '100%', margin: 'auto', position: 'relative' }}
    >
      {Object.keys(tasks).length ? (
        <div>
          {Object.entries(tasks).map(([projectName, subProjects], i) => {
            return (
              <div
                key={projectName + i}
                style={{
                  boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                  marginBottom: '1rem',
                  borderRadius: '10px',
                }}
              >
                {/* Display the project name */}
                <span
                  style={{
                    fontWeight: 'bold',

                    display: 'flex',
                    margin: '1rem',
                    marginBottom: '2rem',

                    width: 'fit-content',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FaProjectDiagram
                    style={{ marginRight: '10px' }}
                    color={themeColor}
                  />
                  <span>{projectName}</span>
                </span>

                {/* Render subProjects */}
                {Object.entries(subProjects).map(
                  ([subProjectName, tasks], j) => (
                    <div
                      key={j + subProjectName}
                      style={{ marginLeft: '2rem' }}
                    >
                      {/* Display the subProject name */}

                      <span
                        style={{
                          fontWeight: 'bold',
                          marginBottom: '0.5rem',
                          display: 'flex',
                          margin: '1rem',
                          width: 'fit-content',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <GoProject
                          style={{ marginRight: '10px' }}
                          color={themeColor}
                        />
                        <span>{subProjectName}</span>
                      </span>

                      {/* Render subTasks */}
                      {(tasks as SubTask[])!.length
                        ? [...(tasks as any[])].map(
                            (subTask: SubTask, k: number) => (
                              <div
                                key={subTask._id}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  marginBottom: '0.2rem',
                                  padding: '0.2rem 1rem',
                                  backgroundColor: '#38383822',
                                }}
                              >
                                <FormLabel
                                  style={{ marginRight: '1rem', width: '30%' }}
                                >
                                  {`${subTask?.name}:`}
                                </FormLabel>

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    width: '20%',
                                  }}
                                >
                                  <div className="switch-wrapper">
                                    <label>Hemifrån</label>
                                    <Switch
                                      onChange={(e) => {
                                        const foundEntry = workEntries.find(
                                          (entry) => entry.id === subTask.name
                                        )

                                        if (
                                          (foundEntry?.spendedTime ?? 0) > 0
                                        ) {
                                          const isChecked = e.target.checked
                                          let workEntry = foundEntry

                                          if (!workEntry) {
                                            workEntry = {
                                              id: subTask.name as string,
                                              beginTime:
                                                moment(currentDate).toDate(),
                                              endTime: moment(currentDate)
                                                .add(8, 'hours')
                                                .toDate(),
                                              spendedTime: 0,
                                              fromHome: false,
                                            }
                                            setWorkEntries([
                                              ...workEntries,
                                              workEntry,
                                            ])
                                          }

                                          const updateWorkEntry = {
                                            ...workEntry,
                                            fromHome: isChecked,
                                          }

                                          const reportedWorkEntry = {
                                            ...workEntry,
                                            fromHome: false,
                                            spendedTime: workEntry.spendedTime,
                                          }

                                          setWorkEntries((prevEntries) =>
                                            prevEntries.map((entry) =>
                                              entry.id === workEntry!.id
                                                ? updateWorkEntry
                                                : entry
                                            )
                                          )

                                          if (!isChecked) {
                                            setWorkEntries((prevEntries) =>
                                              prevEntries.map((entry) =>
                                                entry.id === workEntry!.id
                                                  ? reportedWorkEntry
                                                  : entry
                                              )
                                            )
                                          }
                                        }
                                      }}
                                      checked={
                                        workEntries.find(
                                          (entry) => entry.id === subTask.name
                                        )?.fromHome || false
                                      }
                                    />
                                  </div>
                                  <StyledBaseInput
                                    aria-label="Demo number input"
                                    value={
                                      workEntries.find(
                                        (r) => r.id === subTask?.name
                                      )?.spendedTime || 0
                                    }
                                    onChange={(event) => {
                                      const value = parseInt(event.target.value)
                                      if (value > 10) return
                                      handleInputChange(
                                        subTask?.name as string,
                                        value
                                      )
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          )
                        : null}

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '2% 3%',
                        }}
                      >
                        <span>Lägga till aktivitet</span>

                        <span>
                          <AddButton
                            onClick={() => {
                              setShowAddTaskModal(true)
                              setSelectedSubProject(subProjectName)
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  )
                )}
              </div>
            )
          })}
          <ModalComponent
            bgColor="#282828"
            isModalOpen={showAddTaskModal}
            setIsModalOpen={setShowAddTaskModal}
            title={`Lägga till aktivitet i ${selectedSubProject}`}
          >
            <div className="add-task-wrapper">
              <div className="add-task-select">
                <InputLabel id="demo-simple-select-label">
                  Välj ett parent
                </InputLabel>{' '}
                {projects.length && (
                  <Select
                    onChange={(e) => {
                      console.log(e.target.value)
                      setSelectedTask(e.target.value)
                    }}
                    placeholder="Parent task"
                    sx={{ width: '100%' }}
                    value={selectedTask}
                    label={selectedTask}
                  >
                    {tasksData?.getTasks
                      ?.filter((t) => t?.project?.name === selectedSubProject)
                      .map((task) => (
                        <MenuItem key={task?._id} value={task?._id as string}>
                          {task?.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </div>
              <div
                className="add-task-input"
                style={{ width: '95%', marginTop: '10px' }}
              >
                {' '}
                <Input
                  placeholder="Aktivitet namn"
                  label="Aktivitet"
                  width="90%"
                  handleChange={(e) => setAddedSubTask(e.target.value)}
                />
              </div>
              <div className="add-task-button">
                <Button
                  width="100%"
                  label={'Lägga till'}
                  loading={addSubTaskLoading}
                  onClick={() => {
                    addSubTask({
                      variables: {
                        subTaskName: addedSubTask,
                        taskId: selectedTask as string,
                        startTid: 0,
                        estimatedFinishTime: 0,
                        workers: [],
                      },
                      onCompleted: () =>
                        setTimeout(() => {
                          setShowAddTaskModal(false)
                          refetchProjects!()
                        }, 1000),
                    })
                  }}
                />
                {addSubTaskError && (
                  <Info type="error">Unexpected error happedn</Info>
                )}
                {addSubTaskData?.addSubTask && (
                  <Info type="success">klart!</Info>
                )}
                <IoCloseCircle
                  style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                  }}
                  size={33}
                  onClick={() => setShowAddTaskModal(false)}
                />
              </div>
            </div>
          </ModalComponent>
        </div>
      ) : (
        <Info type="warning">ARBETE aktivitet hittas</Info>
      )}

      <div className="time-registering-form__total-times-wrapper">
        <span>Total Registered Arbetstid:</span>
        {`${totalWorkHours > 0 ? totalWorkHours : 0} Timmar`}
      </div>
    </div>
  )
}

interface LeaveEntry {
  id: string
  beginTime: Date
  endTime: Date
  spendedTime: number
  wholeDay: boolean
}
const LeaveRegisteringForm = ({
  tasks,
  currentDate,
  type,
  onGetData,
}: {
  tasks: any[]
  currentDate: string
  type: 'leave' // Clearly define the type
  onGetData: (data: LeaveEntry[]) => void
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [leaveEntry, setLeaveEntry] = useState<LeaveEntry | null>(null)
  const [leaveEntries, setLeaveEntries] = useState<LeaveEntry[]>([])
  const [totalLeavekHours, setTotalLeaveHours] = useState<number>(0)
  const themeObj = useTheme()
  // Send leaveEntries and workEntries to the parent
  useEffect(() => {
    onGetData(leaveEntries)
    setTotalLeaveHours(totalTimeSpent(leaveEntries))
  }, [leaveEntries, onGetData])

  // Utility function to add hours
  const addHours = (date: Date, hours: number) => {
    const result = new Date(date)
    result.setHours(result.getHours() + hours)
    return result
  }

  // Handle input change for both leave and work
  const handleInputChange = (subTaskId: string, value: number) => {
    const currentTime = moment(currentDate)
      .set('hour', 8)
      .set('minutes', 0)
      .toDate()

    // Handle leave entries

    const newLeaveEntry = {
      id: subTaskId,
      beginTime: currentTime,
      endTime: addHours(currentTime, value),
      spendedTime: value,
      wholeDay: false,
    }

    setLeaveEntries((prevEntries) => {
      const existingEntryIndex = prevEntries.findIndex(
        (entry) => entry.id === subTaskId
      )

      if (existingEntryIndex !== -1) {
        // Update existing leave entry
        const updatedEntries = [...prevEntries]
        updatedEntries[existingEntryIndex] = newLeaveEntry
        return updatedEntries
      } else {
        // Add new leave entry
        return [...prevEntries, newLeaveEntry]
      }
    })

    // Open modal if input value > 0
    if (value > 0) {
      setIsModalOpen(true)
      setLeaveEntry(newLeaveEntry)
    }
  }

  return (
    <div
      className="time-registering-form"
      style={{ width: '100%', margin: 'auto', position: 'relative' }}
    >
      <div>
        {Object.values(LeavesVariationsEnum).map((leaveItem, i) => {
          const currentLeaveEntry =
            leaveEntries.find((r) => r.id === leaveItem) || null
          const isWholeDay = currentLeaveEntry?.wholeDay || false
          const spendedTime = currentLeaveEntry?.spendedTime || 0

          return (
            <div
              key={i}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '1rem',
                padding: '0.5rem 1rem',
                backgroundColor: '#38383822',
              }}
            >
              <FormLabel
                style={{
                  marginRight: '1rem',
                  width: '30%',
                  color: 'red !important',
                }}
              >
                <div
                  style={{
                    color: getColorsByType(leaveItem),
                    width: 'fit-content',
                    fontWeight: 'bold',
                    filter: 'drop-shadow(2px 2px 40px gray) invert(0%)',
                  }}
                >{`${convertTimeTypesToSwedishfrom(leaveItem)}:`}</div>
              </FormLabel>

              {/* Display start time for leave entries */}
              {spendedTime > 0 && (
                <FormLabel style={{ marginRight: '1rem', width: '30%' }}>
                  Starttid:{' '}
                  {moment(currentLeaveEntry!.beginTime).format('HH:mm')}
                </FormLabel>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  width: '20%',
                }}
              >
                <div style={{ flexDirection: 'column' }}>
                  <StepLabel>Heldag</StepLabel>
                  <Switch
                    size="small"
                    checked={
                      leaveEntries.find((entry) => entry.id === leaveItem)
                        ?.wholeDay || false
                    }
                    onChange={(e) => {
                      const isChecked = e.target.checked

                      // Find or create the leave entry
                      let leaveEntry = leaveEntries.find(
                        (entry) => entry.id === leaveItem
                      )

                      // If no entry exists, create a default one
                      if (!leaveEntry) {
                        leaveEntry = {
                          id: leaveItem,
                          beginTime: moment(currentDate).toDate(), // Default begin time
                          endTime: moment(currentDate).add(8, 'hours').toDate(), // Default end time (8 hours)
                          spendedTime: 0, // Default to 0 since no input was given
                          wholeDay: false,
                        }
                        setLeaveEntries([...leaveEntries, leaveEntry]) // Add new entry
                      }

                      // Update the leave entry based on the switch toggle
                      const updatedLeaveEntry = {
                        ...leaveEntry,
                        beginTime: isChecked
                          ? moment(currentDate)
                              .set('hour', 8)
                              .set('minutes', 0)
                              .toDate()
                          : leaveEntry.beginTime,
                        endTime: isChecked
                          ? moment(currentDate)
                              .set('hour', 16)
                              .set('minutes', 0)
                              .toDate()
                          : leaveEntry.endTime,
                        wholeDay: isChecked,
                        spendedTime: isChecked ? 8 : leaveEntry.spendedTime, // Set spendedTime to 8 if checked
                      }
                      const revertedLeaveEntry = {
                        ...leaveEntry,
                        beginTime: isChecked
                          ? moment(currentDate)
                              .set('hour', 0)
                              .set('minutes', 0)
                              .toDate()
                          : leaveEntry.beginTime,
                        endTime: isChecked
                          ? moment(currentDate)
                              .set('hour', 0)
                              .set('minutes', 0)
                              .toDate()
                          : leaveEntry.endTime,
                        wholeDay: false,
                        spendedTime: 0, // Set spendedTime to 8 if checked
                      }
                      // Update the state with the modified leave entry
                      setLeaveEntries((prevEntries) =>
                        prevEntries.map((entry) =>
                          entry.id === leaveEntry!.id
                            ? updatedLeaveEntry
                            : entry
                        )
                      )
                      if (!isChecked) {
                        // Update the state with the modified leave entry
                        setLeaveEntries((prevEntries) =>
                          prevEntries.map((entry) =>
                            entry.id === leaveEntry!.id
                              ? revertedLeaveEntry
                              : entry
                          )
                        )
                      }
                      // Update the StyledBaseInput value to reflect spendedTime
                      setLeaveEntry(updatedLeaveEntry) // Optionally track the updated leaveEntry separately
                    }}
                  />
                </div>

                <StyledBaseInput
                  aria-label="Demo number input"
                  value={
                    leaveEntries.find((r) => r.id === leaveItem)?.spendedTime ||
                    0
                  }
                  onChange={(event) => {
                    const value = event.target.value
                    if (value.length > 2) return

                    // Handle input change and create/update leaveEntry if necessary
                    handleInputChange(leaveItem, parseInt(value))
                  }}
                />
              </div>
            </div>
          )
        })}
        <div className="time-registering-form__total-times-wrapper">
          <span>Total Registered Frånvaro:</span>
          {`${totalLeavekHours} Timmar`}
        </div>
      </div>

      {/* Modal for leave entries */}
      {type === 'leave' && isModalOpen && leaveEntry!.spendedTime > 0 && (
        <ModalComponent
          bgColor={themeObj?.theme == 'dark' ? '#383838' : '#fff'}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          width="400px"
        >
          <div
            style={{
              paddingBottom: '1rem',
              color: themeObj?.theme == 'light' ? '#383838' : '#fff',
            }}
          >
            När började frånvaron?
          </div>
          <TimePicker
            handleChange={(e: Date) => {
              if (leaveEntry) {
                const updatedLeaveEntry = {
                  ...leaveEntry,
                  beginTime: addHours(e, 0),
                  endTime: addHours(e, leaveEntry.spendedTime),
                }

                setLeaveEntries((prevEntries) =>
                  prevEntries.map((entry) =>
                    entry.id === leaveEntry.id ? updatedLeaveEntry : entry
                  )
                )

                setLeaveEntry(updatedLeaveEntry)

                setTimeout(() => {
                  setIsModalOpen(false)
                }, 1000)
              }
            }}
            value={leaveEntry?.beginTime || new Date()} // Provide a default value
            label="Starttid"
          />
        </ModalComponent>
      )}
    </div>
  )
}
// Create a styled version of BaseInput
const StyledBaseInput = styled(BaseInput)`
  * {
    text-align: center;
  }
  input {
    // Target the internal input element for further customization
    padding: 8px;
    border: 1px solid ${themeColor};
    width: 20px;
    height: 20px;
    border-radius: 5px;
    textalign: center;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
`
