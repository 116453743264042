import { Route, Routes } from 'react-router-dom'
import { personalNestedRoutes } from 'utils/routesArray'

const UserDashboard = () => {
  // State to manage the current month

  return (
    <div>
      <Routes>
        {[...personalNestedRoutes].map((route) => (
          <Route
            path={route.path.split('/dashboard/user')[1]}
            element={route.component}
          />
        ))}
      </Routes>
    </div>
  )
}

export default UserDashboard
