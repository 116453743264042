import { Alert } from '@mui/material'
import { GoProject } from 'react-icons/go'
import { MdTask } from 'react-icons/md'
import { themeColor } from 'utils/theme'
import './GroupedData.styles.scss'

const GroupedTaskDataComponent = ({
  groupedByData,
}: {
  groupedByData: Record<
    string,
    { timeSpend: string; fromHomeTime: string; task: string; project: string }[]
  >
}) => {
  // Transform groupedByData into an array of objects for sorting
  const dataArray = Object.keys(groupedByData).map((groupedItem) => ({
    key: groupedItem,
    project: groupedItem.split('_')[1], // Extract project from groupedItem
    task: groupedItem.split('_')[0], // Extract task from groupedItem
    items: groupedByData[groupedItem], // SubTime array for the current groupedItem
  }))

  // Sort dataArray by task (ascending)
  dataArray.sort((a, b) => a.task.localeCompare(b.task))

  return (
    <div className="task-grouped-container">
      {/* Header */}
      <div className="labels">
        <MdTask color={themeColor} style={{ marginRight: '3px' }} />
        <span>Task</span>
      </div>

      {/* Main Content */}
      <div className="task-grouped-data">
        {dataArray.length > 0 ? (
          dataArray.map(({ key, project, task, items }, i) => (
            <div key={`${key}+${i}`} className="grouped-task-item">
              {/* Task Header */}
              <div className="task-header">
                <GoProject color={themeColor} style={{ marginRight: '3px' }} />
                <span>{project}</span>
              </div>

              {/* Task Entries */}
              <div className="task-entries">
                <div className="headers">{task}</div>
                {items.map((item, index) => (
                  <div key={index} className="entry">
                    <div className="entry-detail">
                      <strong>Tid:</strong> {item.timeSpend} Timmar
                    </div>
                    <div className="entry-detail">
                      <strong>Hemifrån:</strong> {item.fromHomeTime} Timmar
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          // Fallback when no data is available
          <Alert
            style={{
              padding: 0,
              backgroundColor: 'transparent',
              color: 'orange',
            }}
            severity="info"
          >
            {`Ingen registrerad tid`}
          </Alert>
        )}
      </div>
    </div>
  )
}

export default GroupedTaskDataComponent
