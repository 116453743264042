import { Skeleton } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FaInfo } from 'react-icons/fa'
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md'
import './MonthDetails.scss'; // Adjust the import path as needed

interface SelectionType {
  startDate: Date
  endDate: Date
  key: string
  autoFocus?: boolean
  colors?: [string, string]
  __typename?: string
  leaveType?: string
  type?: string
  color?: string
  disabled?: boolean
  showDateDisplay?: boolean
}

interface StateType {
  [key: string]: SelectionType
}
interface StaticDatePickerProps {
  label?: string
  range: StateType
  loading: boolean
  clickable?: boolean
  theme?: string
  GuildComponent?: JSX.Element
  cellColor: string
  handleDayClick: (item: SelectionType) => void
  onShownDateChange: (date: moment.Moment) => void
  activeDate: moment.Moment
  textColor: string
  beginDayOfWeek: string
}

// Utility functions to handle date manipulations
const cloneDate = (date: Date) => new Date(date.getTime())
const addMonths = (date: Date, months: number) =>
  new Date(date.setMonth(date.getMonth() + months))
const subtractMonths = (date: Date, months: number) =>
  new Date(date.setMonth(date.getMonth() - months))
const startOfMonth = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), 1)
const endOfMonth = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0)
const isSameDay = (d1: Date, d2: Date) => {
  if (new Date(d1) instanceof Date && new Date(d2) instanceof Date) {
    if (new Date(d1).toDateString() === new Date(d2).toDateString()) {
      return true
    }
  }
}

const isBetween = (date: Date, startDate: Date, endDate: Date) =>
  date >= startDate && date <= endDate

const RangeViewer = ({
  label,
  range,
  handleDayClick,
  onShownDateChange,
  GuildComponent,
  loading,
  cellColor,
  theme,
  activeDate,
  textColor,
  clickable,
  beginDayOfWeek,
}: StaticDatePickerProps) => {
  const [currentMonth, setCurrentMonth] = useState(activeDate ?? moment())
  const [extendedLoading, setExtendedLoading] = useState(false)
  const goToPreviousMonth = () => {
    const nextMonth = currentMonth.clone().subtract(1, 'month')
    setCurrentMonth(nextMonth)
  }

  const goToNextMonth = () => {
    const nextMonth = currentMonth.clone().add(1, 'month')
    setCurrentMonth(nextMonth)
  }
  useEffect(() => {
    onShownDateChange(currentMonth)
  }, [currentMonth])

  useEffect(() => {
    setExtendedLoading(true)
    const timeout = setTimeout(() => {
      if (!loading && range) setExtendedLoading(false)
    }, 200)

    return () => clearTimeout(timeout)
  }, [loading, range])

  const getWeekOfMonth = (date: Date) => {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
    const firstDayOfWeek = (startOfMonth.getDay() + 6) % 7 // Monday = 0, Sunday = 6
    const dayOfMonth = date.getDate()
    return Math.ceil((dayOfMonth + firstDayOfWeek) / 7) - 1
  }

  const renderCalendar = () => {
    const weeks: JSX.Element[][] = []
    const startOfCurrentMonth = startOfMonth(currentMonth.toDate())
    const endOfCurrentMonth = endOfMonth(currentMonth.toDate())
    const weekStartDay = beginDayOfWeek === 'monday' ? 1 : 0 // Monday = 1, Sunday = 0
    const cellTextColor = 'white'

    let currentDate = cloneDate(startOfCurrentMonth)

    // Calculate the offset for the first day of the month
    const offset = (currentDate.getDay() - weekStartDay + 7) % 7

    // Start with an empty week for the first row
    let week: JSX.Element[] = []

    // Fill the offset with blank cells
    for (let i = 0; i < offset; i++) {
      week.push(
        <div
          key={`empty-${i}`}
          style={{
            padding: 0,
            height: '30px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '3px auto',
            backgroundColor: 'transparent',
          }}
        />
      )
    }

    // Add actual days to the calendar
    while (currentDate <= endOfCurrentMonth) {
      let color = 'transparent'
      let firstDayInRange = false
      let lastDayInRange = false
      let isInRange = false
      let rangeObj: SelectionType | null = null

      // Check if the current date is within a range
      range &&
        Object.values(range).forEach((range) => {
          const start = range.startDate
          const end = range.endDate

          if (
            isSameDay(start, currentDate) ||
            isSameDay(end, currentDate) ||
            moment(currentDate).isBetween(
              moment(range.startDate),
              moment(range.endDate),
              null,
              '[]'
            )
          ) {
            isInRange = true
            firstDayInRange = isSameDay(start, currentDate) as boolean
            lastDayInRange = isSameDay(end, currentDate) as boolean
            color = range.color || 'transparent'
            rangeObj = range
          }
        })

      // Add the day cell to the current week
      week.push(
        <div
          key={currentDate.toDateString()}
          className="day-cell"
          style={{
            height: '30px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '3px auto',
            backgroundColor: color,
            fontWeight: 'bold',

            color: isInRange ? 'white' : theme === 'dark' ? 'white' : '#282828',
            borderTopLeftRadius: firstDayInRange ? '20px' : 'initial',
            borderBottomLeftRadius: firstDayInRange ? '20px' : 'initial',
            borderTopRightRadius: lastDayInRange ? '20px' : 'initial',
            borderBottomRightRadius: lastDayInRange ? '20px' : 'initial',
            border: isSameDay(new Date(), currentDate)
              ? '2px solid lightGray'
              : 'none',
          }}
          onClick={() => {
            if (rangeObj) {
              handleDayClick(rangeObj)
            }
          }}
        >
          {currentDate.getDate()}
        </div>
      )

      // Move to the next day
      currentDate = addMonths(cloneDate(currentDate), 0)
      currentDate.setDate(currentDate.getDate() + 1)

      // If the week is full, push it to the weeks array
      if (week.length === 7) {
        weeks.push(week)
        week = []
      }
    }

    // Add the last incomplete week
    if (week.length > 0) {
      while (week.length < 7) {
        week.push(
          <div
            key={`empty-end-${week.length}`}
            style={{
              padding: 0,
              height: '30px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '3px auto',
              backgroundColor: 'transparent',
            }}
          />
        )
      }
      weeks.push(week)
    }

    // Render all weeks as rows
    return weeks.map((week, index) => (
      <div
        key={index}
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          gap: '0.2px',
        }}
      >
        {week}
      </div>
    ))
  }

  const renderDayHeaders = () => {
    const days = moment.weekdaysShort()
    const adjustedDays =
      beginDayOfWeek === 'monday' ? [...days.slice(1), days[0]] : days
    return adjustedDays.map((day, index) => (
      <div
        key={index}
        style={{
          padding: '10px',
          textAlign: 'center',
          fontWeight: 'bold',
          color: cellColor,
        }}
      >
        {day}
      </div>
    ))
  }

  return (
    <div className="month-details-container" style={{ position: 'relative' }}>
      <div>
        <div className="arrows-container">
          <button onClick={goToPreviousMonth}>
            <span>
              <MdOutlineKeyboardArrowLeft size={29} color={cellColor} />
            </span>
          </button>
          <span style={{ color: cellColor, textShadow: 'none' }}>{`${moment(
            currentMonth
          ).format('MMMM YYYY')}`}</span>
          <button onClick={goToNextMonth}>
            <span>
              <MdOutlineKeyboardArrowRight size={29} color={cellColor} />
            </span>
          </button>
        </div>
      </div>

      <div className="users-container">
        <div
          className="month-details-container--header-container"
          style={{
            backgroundColor: theme == 'light' ? 'lightGray' : '#383838',
          }}
        >
          <FaInfo color="#49b4b8" size={22} style={{ marginRight: '3px' }} />
          <div className="place-middle">{GuildComponent}</div>
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(7, 1fr)`,
            gridTemplateRows: 'auto',
            padding: '1% 2%',
            backgroundColor: '#38383822',
          }}
        >
          {renderDayHeaders()}
        </div>
        <div
          style={{
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
            padding: '2%',
          }}
        >
          {extendedLoading
            ? new Array(5)
                .fill('')
                .map((r, i) => (
                  <Skeleton
                    height={'40px'}
                    key={i}
                    width={'100%'}
                    animation="wave"
                  />
                ))
            : renderCalendar()}
        </div>
      </div>
    </div>
  )
}

export default RangeViewer
